import './css/knowmore.css';
import KnowMoreImg from './images/knowmore.webp';

function KnowMore(){
    return(
        <>
            <div className='know-more'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='know-more-content'>
                                <div className='tag'>
                                    <span>  5K+ </span>
                                    <span> In Stock </span>
                                </div>
                                <div className='images'>
                                    <img src={KnowMoreImg} alt="knowmore image" />
                                    <div className='overlay'></div>
                                    <a target='_blank' href="https://wa.me/+447507877662">
                                        <i class="las la-phone-volume"></i>
                                    </a>
                                </div>

                                <div className='text'>
                                    <i class="las la-tshirt"></i>
                                    <h2>  
                                        Returns Revolution
                                    </h2>
                                    <h1>
                                        Discover the Difference: Quality Returns Pallets from Top Brands
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KnowMore;