import './css/map.css';

function Map(){
    return(
        <>
            <div className='map'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.1206528020007!2d-0.1870642765657214!3d51.49265337180997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760f8ac1f7d8c9%3A0x5e9a09a5c5639d3e!2zMTggQ291cnRmaWVsZCBHYXJkZW5zLCBMb25kb24gU1c1IDBQRNiMINin2YTZhdmF2YTZg9ipINin2YTZhdiq2K3Yr9ip!5e0!3m2!1sar!2seg!4v1699601324412!5m2!1sar!2seg" width="100%" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>
    )
}

export default Map;