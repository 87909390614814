import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import UpperNav from './components/UpperNav';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import KnowMore from './components/KnowMore';
import Services from './components/Services';
import Benefits from './components/Benefits';
import AboutUs from './components/AboutUs';
import Numbers from './components/Numbers';
import Gallery from './components/Gallery';
import ContactDetails from './components/ContactDetails';
import Questions from './components/Questions';
import Map from './components/Map';
import Footer from './components/Footer';


function App() {

return (
    <div className="App">

<UpperNav />
                        <Navbar />
            <Routes>
            

            <Route path="/" element={
                    <>

                        <Landing />
                        <KnowMore />
                        <Services />
                        <Benefits />
                        <AboutUs />
                        <Numbers />
                        <Gallery />
                        <ContactDetails />
                        <Questions />
                        <Map />

                    </>
                } />


                <Route path="/about-us" element={
                    <>
                        <AboutUs />
                        <KnowMore />
                        <Numbers />
                        <Gallery />
                        <ContactDetails />
                        <Questions />
                        <Map />

                    </>
                } />


                <Route path="/our-services" element={
                    <>
                        <Services />
                        <Benefits />
                        <AboutUs />
                        <Numbers />
                        <Questions />
                        <Map />
                    </>
                } />

                    <Route path="/why-us" element={
                                <>
                                    <KnowMore />
                                    <Benefits />
                                    <AboutUs />
                                    <Numbers />
                                    <Questions />
                                    <Map />
                                </>
                            } />


                            
                    <Route path="/contact-us" element={
                                <>
                                    <ContactDetails />
                                    <Questions />
                                    <Map />
                                </>
                            } />


        </Routes>

        <Footer />

    </div>
);
}

export default App;
