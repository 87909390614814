import './css/contactdetails.css';


function ContactDetails(){
    return(
        <>
            <section className='contact-details'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='detail'>
                            <i class="las la-phone-volume"></i>
                                <a href="tel:+447507877662">
                                    <span> Have any question ? </span>
                                    +44 750 787 7662
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='detail'>
                            <i class="las la-envelope"></i>
                                <a href="mailto:celinehiriart@hotmail.com">
                                    <span>Mail any question ?  </span>
                                    celinehiriart@hotmail.com
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='detail'>
                                <i class="las la-map-marker"></i>
                                <a href="https://maps.app.goo.gl/nnZeACrttzYWF4gRA">
                                    <span> Our Office Location </span>
                                    18 courtfield gardens sw50pd London 
                                    Director : C hiriart durruthy
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactDetails;