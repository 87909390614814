import './css/numbers.css';

function Numbers(){
    return(
        <>
            <section className='numbers'>
                <div className='overlay'></div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='numbers-left'>
                                <i class="las la-tshirt"></i>
                                <h2> Explore the Numbers Behind Our Success </h2>
                                <h1> At HIRIART | TRADER LTD - Returns Pallets </h1>
                                <p> At Returns Pallets LONDON, we take pride in the quantitative aspects that define our commitment to excellence. Let the numbers speak for themselves: </p>

                                <div className='all-numbers'>
                                    <div className='number'>
                                        <h3> 200+ </h3>
                                        <span> </span>
                                        <p> High-Street Brands </p>
                                    </div>

                                    <div className='number'>
                                        <h3> 90% </h3>
                                        <span> </span>
                                        <p>  Customer Satisfaction Rate </p>
                                    </div>

                                    <div className='number'>
                                        <h3> 500+ </h3>
                                        <span> </span>
                                        <p> Fresh Stocks Weekly </p>
                                    </div>
                                </div>

                                <a href="https://wa.me/+447507877662"> 
                                    Contact With Us For Complaints
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='numbers-right'>
                                <div>
                                    <span className='line'></span>
                                    <div className='number-count'>
                                        1
                                    </div>
                                    <div className='number-count'>
                                        2
                                    </div>
                                    <div className='number-count'>
                                        3
                                    </div>
                                </div>

                                <div>
                                    <div className='number-content'>
                                        <div className='tag'>
                                            <i class="las la-frown"></i>
                                        </div>

                                        <h5> 01 </h5>
                                        <h6> Unhappy With Your Service </h6>
                                    </div>

                                    <div className='number-content'>
                                        <div className='tag'>
                                        <i class="las la-sms"></i>
                                        </div>

                                        <h5> 02 </h5>
                                        <h6> Talk To Us </h6>
                                    </div>

                                    <div className='number-content'>
                                        <div className='tag'>
                                            <i class="las la-reply-all"></i>
                                        </div>

                                        <h5> 03 </h5>
                                        <h6>  Make A Complaint With Us </h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Numbers;