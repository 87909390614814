import './css/landing.css';
import LandingBack from './images/landing.webp';
import { Link as RouterLink } from 'react-router-dom';


function Landing(){
    return(
        <>
            <section className='landing'>

                <div className='overlay'></div>
                <img src={LandingBack} alt="landing background img" />


                <div className='landing-content'>
                    <div className='circle'></div>
                    <h2><span> Unlock Savings: </span> Explore Our Liquidation Pallets for High-End Fashion </h2>
                    <h1> Premium Liquidation Pallets for Discerning Retailers </h1>

                    <RouterLink to="/about-us">
                        What We Do
                    </RouterLink>
                </div>


            </section>
        </>
    )
}

export default Landing;