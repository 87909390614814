import './css/navbar.css';
import Logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect,useRef,useState  } from "react";
import $ from 'jquery';

function Navbar(){

    
    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-elements").slideDown(400);
            $(".nav-elements").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-elements").slideUp(400);
        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li").on("click", function() {
                $(".nav-elements").slideUp(400);
            });
        }
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.remove();
    }
    }, []);
    





    return(
        <>
            <nav>
                <div className='container-fluid'>
                    <div className='nav-content'>

                        <div className='left'>
                            <div className='logo'>
                                <img src={Logo} alt="logo" />
                            </div>

                            <button id="open-nav"> <i className="las la-bars"></i> </button>
                            <div className="nav-elements">
                            <button id="close-nav"> <i className="las la-times"></i> </button>
                                <ul>
                                    <li>
                                        <RouterLink to="/">
                                            Home
                                        </RouterLink> 
                                    </li>

                                    <li>
                                        <RouterLink to="/about-us">
                                            About Us
                                        </RouterLink> 
                                    </li>

                                    <li>
                                        <RouterLink to="/our-services">
                                            Our Services
                                        </RouterLink> 
                                    </li>

                                    <li>
                                        <RouterLink to="/why-us">
                                            Why Us ? 
                                        </RouterLink> 
                                    </li>

                                    <li>
                                        <RouterLink to="/contact-us">
                                            Contact Us
                                        </RouterLink> 
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div className='right'>
                            <div className='phone'>
                                <i class="las la-phone-volume"></i>
                                <a target='_blank' href="tel:+447507877662">
                                    <span>PHONE: </span>
                                    +44-750-787-7662
                                </a>
                            </div>

                            <div className='whatsapp'>
                                <a target='_blank' href="https://wa.me/+447507877662">
                                    SEND MESSAGE
                                    <i class="las la-sms"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}
export default Navbar;