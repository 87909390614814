import './css/footer.css';

import Logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';

function Footer(){
    return(
        <>
            <footer>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                            <div className='footer-content'>
                                <h1> Don't hesitate to contact us. Your satisfaction is our priority </h1>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                        <div className='logo'>
                                            <img src={Logo} alt="logo" />
                                        </div>

                                        <p> 
                                        If you have additional questions, don't hesitate to contact us. Your satisfaction is our priority, and we're here to ensure a seamless and enjoyable experience with Returns Pallets LONDON.
                                        </p>

                                        <a href="mailto:celinehiriart@hotmail.com">
                                            <i class="las la-envelope"></i>
                                            celinehiriart@hotmail.com
                                        </a>
                                        <a href='https://maps.app.goo.gl/nnZeACrttzYWF4gRA'>
                                            <i class="las la-map-marker"></i>
                                            18 courtfield gardens sw50pd London 
                                            Director : C hiriart durruthy
                                        </a>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                        <h1> Short Links </h1>
                                        <ul>
                                            <li>
                                                <RouterLink to="/">
                                                    Home
                                                </RouterLink> 
                                            </li>

                                            <li>
                                                <RouterLink to="/about-us">
                                                    About Us
                                                </RouterLink> 
                                            </li>

                                            <li>
                                                <RouterLink to="/our-services">
                                                    Our Services
                                                </RouterLink> 
                                            </li>

                                            <li>
                                                <RouterLink to="/why-us">
                                                    Why Us ? 
                                                </RouterLink> 
                                            </li>

                                            <li>
                                                <RouterLink to="/contact-us">
                                                    Contact Us
                                                </RouterLink> 
                                            </li>

                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='footer-content'>
                                <form>
                                    <div className='input'>
                                        <i class="las la-user"></i>
                                        <input name='name' type="text" required placeholder='Your Name' />
                                    </div>

                                    <div className='input'>
                                        <i class="las la-envelope"></i>
                                        <input name='email' type="email" required placeholder='Email Address' />
                                    </div>
                                    <div className='input'>
                                    <i class="las la-edit"></i>
                                        <textarea name='message' required placeholder='Enter Your Message'>

                                        </textarea>
                                    </div>

                                    <a target='_blank' href="mailto:celinehiriart@hotmail.com">
                                        Get In Touch
                                        <i class="las la-paper-plane"></i>
                                    </a>
                                </form>
                            </div>
                        </div>


                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='copy-right'>
                                <h6> &copy; 2023 Hiriart. All Rights Reserved </h6>
                                <ul>
                                <li>
                                    <a href="#">
                                        <i class="lab la-facebook-f"></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="#">
                                        <i class="lab la-twitter"></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="#">
                                        <i class="lab la-instagram"></i>
                                    </a>
                                </li>


                                <li>
                                    <a href="#">
                                        <i class="lab la-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;