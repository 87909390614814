import './css/questions.css';
import React, { useState } from 'react';

function Questions(){
    const [activeQuestion, setActiveQuestion] = useState(0);

    const questions = [
    {
        question: 'What makes your liquidation pallets stand out?',
        answer:
        'Our pallets are unique for their affordability, containing fresh and high-quality stocks from over 200 high-street brands. Transparency is key, with customer returns clearly marked for your informed selection.',
    },
    {
        question: 'Can I customize my order to match specific preferences?',
        answer:
        'Absolutely! We offer a personalized shopping experience, allowing you to tailor your order based on brands, styles, or product categories. Just let us know your preferences, and we\'ll curate pallets accordingly.',
    },
    {
        question: 'How do you ensure the quality of the merchandise?',
        answer:
        'Quality is our priority. Our Returns Verification Service meticulously inspects and validates customer returns, ensuring that you receive authentic and high-quality products with each purchase.',
    },
    ];

    const handleQuestionClick = (index) => {
    setActiveQuestion(index);
    };


    return(
        <>
            <section className='questions'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='questions-content'>
                                <div className='left'>
                                    <i class="las la-tshirt"></i>
                                    <h2> Frequently Asked Questions </h2>
                                    <h1>Curious about how Returns Pallets LONDON operates?  </h1>

                                    <ul>
                                        <li>
                                            <i class="las la-check-circle"></i>
                                            Liquidation Luxe
                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            Returns Revolution
                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            Chic for Less
                                        </li>
                                    </ul>

                                    <a href="tel:+447507877662">
                                        <i class="las la-phone"></i>
                                        +44-750-787-7662
                                    </a>
                                </div>


                                <div className='right'>
                                {questions.map((q, index) => (
                                        <div
                                        key={index}
                                        className={`question ${activeQuestion === index || (index === 0 && activeQuestion === -1) ? 'active' : ''}`}
                                        onClick={() => handleQuestionClick(index)}
                                        >
                                        <h1>
                                            {q.question} <i className='las la-plus'></i>
                                        </h1>
                                        <p>{q.answer}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Questions;