import './css/gallery.css';

import Galleryimg1 from './images/gal1_1.webp';
import Galleryimg2 from './images/gal2_2.webp';
import Galleryimg3 from './images/gal3_0.webp';


function Gallery(){
    return(
        <>
            <section className='gallery'>
                <div className='header'>
                    <i class="las la-tshirt"></i>
                    <h2> Fashion Finds Await </h2>
                    <h1> Our pallets are filled with new, trendy stocks </h1>
                </div>

                <div className='images'>
                    <img src={Galleryimg1} alt="gallery img" />
                    <img src={Galleryimg2} alt="gallery img" />
                    <img src={Galleryimg3} alt="gallery img" />

                    <div className='gallery-contact'>
                        <a href="tel:+447507877662"> CALL US </a>
                        <div> 
                            <span> </span>
                                <h1> 5-Star Customer Support </h1>
                                <h2> efficiency and responsiveness </h2>
                            <span> </span>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Gallery;