import './css/services.css';

import Service1 from './images/ser1.webp';
import Service2 from './images/ser2.webp';
import Service3 from './images/ser3.webp';
import Service4 from './images/ser4.webp';


function Services(){
    return(
        <>
            <section className='services'>
                <div className='container-fluid'>
                    <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='header'>
                            <i class="las la-tshirt"></i>
                            <h2> Brand Bargains </h2>
                            <h1>  Explore the wealth of services we offer </h1>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                        <div className='service'>
                            <img src={Service1} alt="Returns Verification Service"/>
                            <h1> <i class="las la-undo-alt"></i> Returns Verification Service</h1>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                        <div className='service'>
                            <img src={Service2} alt="Customized Pallet Selection"/>
                            <h1> <i class="las la-tasks"></i> Customized Pallet Selection</h1>
                        </div>
                    </div>


                    <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                        <div className='service'>
                            <img src={Service3} alt="Express Shipping Options"/>
                            <h1> <i class="las la-shipping-fast"></i> Express Shipping Options</h1>
                        </div>
                    </div>


                    <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                        <div className='service'>
                            <img src={Service4} alt=" Dedicated Customer Support"/>
                            <h1> <i class="las la-user-astronaut"></i>  Dedicated Customer Support</h1>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services;