import './css/benefits.css';

function Benefits(){
    return(
        <>
            <section className='benefits'>
                <div className='overlay'></div>
                <div className='container-fluid'>
                    <div className='benefits-content'>


                        <div className='header'>
                            <div className='left'>
                                <i class="las la-tshirt"></i>
                                <h2> Unlock the Benefits of Shopping with Us </h2>
                                <h1>
                                
                                We're committed to delivering value beyond the purchase.
                                </h1>
                            </div>

                            <div className='right'>
                                <p>  
                                    Welcome to a world of unparalleled advantages at our Returns Pallets LONDON and liquidation clothing stock page. When you choose to buy from us, you're not just acquiring merchandise; you're investing in a range of benefits that set us apart.
                                </p>
                            </div>
                        </div>

                        <div className='all-benefits'>
                            <div className='benefit'>
                                <div className='overlay-line'></div>
                                <i class="las la-hand-holding-usd"></i>
                                <h3> Affordability at Its Finest </h3>
                                <p> Explore and purchase budget-friendly liquidation pallets</p>
                                <a target='_blank' href="https://wa.me/+447507877662">
                                    contact us <i class="las la-arrow-right"></i>
                                </a>
                            </div>

                            <div className='benefit'>
                                <div className='overlay-line'></div>
                                <i class="las la-tshirt"></i>
                                <h3> Fresh and Trendy Stocks </h3>
                                <p> Stay ahead in the fashion game with liquidation pallets </p>
                                <a target='_blank' href="https://wa.me/+447507877662">
                                    contact us <i class="las la-arrow-right"></i>
                                </a>
                            </div>


                            <div className='benefit'>
                                <div className='overlay-line'></div>
                                <i class="las la-thumbs-up"></i>
                                <h3> Uncompromised Quality </h3>
                                <p> Elevate your product offerings with high-quality merchandise sourced </p>
                                <a target='_blank' href="https://wa.me/+447507877662">
                                    contact us <i class="las la-arrow-right"></i>
                                </a>
                            </div>


                            <div className='benefit'>
                                <div className='overlay-line'></div>
                                <i class="las la-undo-alt"></i>
                                <h3>Transparency in Returns</h3>
                                <p> Experience peace of mind with our customer returns pallets </p>
                                <a target='_blank' href="https://wa.me/+447507877662">
                                    contact us <i class="las la-arrow-right"></i>
                                </a>
                            </div>


                            
                            <div className='benefit'>
                                <div className='overlay-line'></div>
                                <i class="las la-store-alt"></i>
                                <h3> Personalized Shopping Experience </h3>
                                <p> Tailor your buying experience with the option to choose pallets  </p>
                                <a target='_blank' href="https://wa.me/+447507877662">
                                    contact us <i class="las la-arrow-right"></i>
                                </a>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Benefits;