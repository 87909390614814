import './css/about.css';

import AboutImg from './images/about.webp';

function AboutUs(){
    return(
        <>
            <section className='about-us'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-images'>
                                <div className='overlay-left'>

                                    <div className='top-lines'></div>
                                        <h1> HIRIART | TRADER LTD - Returns Pallets </h1>
                                    <div className='bottom-lines'></div>

                                </div>

                                <div className='tag'> <span> 1k + </span> <span> Happy Clients </span></div>

                                <div className='number'>
                                    <div className='line'></div>
                                    <h1> 2023 </h1>
                                    <span> Fresh Stocks</span>
                                </div>

                                <img src={AboutImg} alt="about us image" />
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-text'>
                                <i class="las la-tshirt"></i>
                                <h2> At HIRIART | TRADER LTD </h2>
                                <h1>  Filled with new stocks and high-quality products  </h1>
                                <p>  
                                    At HIRIART | TRADER LTD, we are thrilled to welcome you to our Returns Pallets LONDON and liquidation clothing stock page. Here, you will discover an exciting opportunity to purchase affordable liquidation pallets filled with new stocks and high-quality products sourced from renowned high-street brands.
                                </p>

                                <h3> Why Choose Our Liquidation Pallets </h3>

                                <ul>
                                    <li>
                                        <i class="lar la-check-circle"></i>
                                        Cost-Effective Solutions
                                    </li>
                                    <li>
                                        <i class="lar la-check-circle"></i>
                                        Fresh Stocks
                                    </li>
                                    <li>
                                        <i class="lar la-check-circle"></i>
                                        High-Quality Products
                                    </li>
                                </ul>

                                <div className='contact-info'>
                                    <a href="tel:+447507877662">
                                        <i class="las la-phone-volume"></i>
                                        +44-750-787-7662
                                    </a>

                                    <a href="https://wa.me/+447507877662">
                                        SEND MESSAGE
                                    </a>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs;