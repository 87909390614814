import './css/uppernav.css';


function UpperNav(){
    return(
        <>
            <div className='upper-nav'>
                <div className='container-fluid'>
                    <div className='upper-content'>
                        <div className='left'>
                            <a href="tel:+447507877662"><i class="las la-phone-volume"></i>  +447 507 877 662</a>
                            <a href="mailto:celinehiriart@hotmail.com"><i class="las la-envelope"></i>  celinehiriart@hotmail.com</a>
                        </div>

                        <div className='right'>
                            <h3><i class="las la-calendar"></i>   Mon - Fri 8:00 - 18:00 / Sunday 8:00 - 14:00</h3>
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="lab la-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="lab la-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="lab la-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="lab la-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpperNav;